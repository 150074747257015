import { CommonModule } from '@angular/common';
import { Component, ElementRef, forwardRef, Input, OnInit, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TranslatePipe } from '@ngx-translate/core';
import TomSelect from 'tom-select';

@Component({
    selector: 'app-select',
    templateUrl: './select.component.html',
    styleUrls: ['./select.component.scss'],
    imports: [CommonModule, TranslatePipe],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => SelectComponent),
            multi: true,
        },
    ],
})
export class SelectComponent implements OnInit, ControlValueAccessor {
    @ViewChild('select', { static: true }) select: ElementRef;
    @Input() placeholder: string;
    @Input() multiple: boolean = false;
    @Input() options: { value: string; label: string }[];
    @Input() required: boolean = false;
    private onChange: (value: any) => void;
    private onTouch: () => void;
    private tomSelect: TomSelect;
    @Input() isInvalid: boolean = false;

    constructor() {}

    ngOnInit() {
        // New tom select
        this.tomSelect = new TomSelect(this.select.nativeElement, {
            plugins: this.multiple ? ['remove_button'] : [],
            create: false,
            allowEmptyOption: !this.required,
            maxOptions: 30,
            valueField: 'value',
            labelField: 'label',
            searchField: ['label'],
            sortField: 'label',
            options: this.options as any[],
            maxItems: this.multiple ? 1000 : 1,
            onFocus: () => {
                this.onTouch?.();
            },
            onChange: (val: any) => {
                this.onChange?.(this.multiple ? val : val ? [val] : []);
            },
            onItemAdd: () => {
                // Empty typed value
                this.tomSelect.setTextboxValue('');
                this.tomSelect.refreshOptions();
            },
        });
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouch = fn;
    }

    writeValue(obj: any): void {
        this.tomSelect.setValue(obj);
    }
}
